.cyber-card{
    height: 300px;
    width: 650px;
    padding: 15px;
    background-color: RGBA(0,0,0,0.75);
    color: #FFF;
    font-family: sans-serif;
    font-size: 12px;
    border: 4px solid white;
    border-radius: 35px 0 35px 0;
    display: inline-block;
    transition: all 500ms;
}
/*
-95% up
-5% down


 */
div > div[style*="-95%"] .cyber-card.last{
    display: none;
    transition: display 1s;
}
div > div[style*="-5%"] .cyber-card.first{
    display: none;
}
.hudWrapper{
    position:relative;
}

.hud-text{
    width: 525px;
    padding: 25px;
    position:absolute;
    z-index: 3;
    color: white;
    font-size: 40px;
}

@media screen and (max-width: 768px) {
    .hud-text{
        width: 325px;
        padding: 25px;
        position:absolute;
        z-index: 3;
        color: white;
        font-size: 25px;
    }
}

.hudCard {
    position: absolute;
    padding: 25px;
    width: max-content;
    height: max-content ;
    background-color: RGBA(0,0,0,0.75);
    border-radius: 20% 0 20% 0;
    border: 4px solid white
}


.hudCard span,
.hudCard p {
    margin: 0;
    font-size: 25px;
    font-weight: 1000;
    line-height: 20px;
    height: 25px;
}
.hudCard span {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    color: darkslateblue;

}
.hudCard span:after {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}
.hudCard span:hover:after {
    opacity: 1;
}
.hudCard span.purple {

    color: RGBA(204, 0, 204, 0.5);
}
.hudCard span.purple:hover:after {
    background-color: darkslategray;
}


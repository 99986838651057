#glitchyCanvasWrapper{
    height: max-content;
    width: max-content;
    position: absolute;
    z-index: 0;
    padding-top: 35px;
    padding-left: 35px;


}

#glitchyCanvasWrapper canvas{
    opacity: 0.35;
    border-radius: 15% 0 0 0
}

#glitchyCanvasWrapper::before{
    content: '';
    display: inline-block;
    width: 550px;
    height: 550px;
    position: absolute;
    z-index: 1;
    border: 4px solid white;
    border-radius: 15% 0 0 0
}

@media screen and (max-width: 768px) {
    #glitchyCanvasWrapper{
        padding-top: 25px;
        padding-left: 25px;
    }

    #glitchyCanvasWrapper::before{
        content: '';
        display: inline-block;
        width: 325px;
        height: 325px;
        position: absolute;
        z-index: 1;
        border: 4px solid white;
        border-radius: 15% 0 0 0
    }
}

p.glitch{
    line-height: 0.6;
    margin-top: 25px;

}

.glitch {
    font-family: "Hacked";
    color: white;
    font-size: clamp( 25px , 80px , 100px );
    font-weight: bolder;
    position: relative;
    width: max-content;
}
@media screen and (max-width: 768px) {
    .glitch {
        margin: 0
    }
}

@keyframes noise-anim {
    0% {
        clip: rect(52px, 9999px, 27px, 0);
    }
    5% {
        clip: rect(92px, 9999px, 8px, 0);
    }
    10% {
        clip: rect(94px, 9999px, 79px, 0);
    }
    15% {
        clip: rect(1px, 9999px, 76px, 0);
    }
    20% {
        clip: rect(15px, 9999px, 76px, 0);
    }
    25% {
        clip: rect(53px, 9999px, 18px, 0);
    }
    30% {
        clip: rect(49px, 9999px, 62px, 0);
    }
    35% {
        clip: rect(89px, 9999px, 84px, 0);
    }
    40% {
        clip: rect(12px, 9999px, 43px, 0);
    }
    45% {
        clip: rect(83px, 9999px, 75px, 0);
    }
    50% {
        clip: rect(87px, 9999px, 76px, 0);
    }
    55% {
        clip: rect(36px, 9999px, 6px, 0);
    }
    60% {
        clip: rect(85px, 9999px, 1px, 0);
    }
    65% {
        clip: rect(65px, 9999px, 60px, 0);
    }
    70% {
        clip: rect(51px, 9999px, 20px, 0);
    }
    75% {
        clip: rect(73px, 9999px, 67px, 0);
    }
    80% {
        clip: rect(31px, 9999px, 9px, 0);
    }
    85% {
        clip: rect(95px, 9999px, 24px, 0);
    }
    90% {
        clip: rect(31px, 9999px, 52px, 0);
    }
    95% {
        clip: rect(19px, 9999px, 92px, 0);
    }
    100% {
        clip: rect(42px, 9999px, 67px, 0);
    }
}
.glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: RGBA( 0 , 0 , 0 , 0.0);
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
    0% {
        clip: rect(95px, 9999px, 100px, 0);
    }
    5% {
        clip: rect(64px, 9999px, 18px, 0);
    }
    10% {
        clip: rect(52px, 9999px, 92px, 0);
    }
    15% {
        clip: rect(35px, 9999px, 5px, 0);
    }
    20% {
        clip: rect(30px, 9999px, 35px, 0);
    }
    25% {
        clip: rect(89px, 9999px, 98px, 0);
    }
    30% {
        clip: rect(31px, 9999px, 79px, 0);
    }
    35% {
        clip: rect(62px, 9999px, 59px, 0);
    }
    40% {
        clip: rect(55px, 9999px, 3px, 0);
    }
    45% {
        clip: rect(26px, 9999px, 73px, 0);
    }
    50% {
        clip: rect(62px, 9999px, 40px, 0);
    }
    55% {
        clip: rect(89px, 9999px, 34px, 0);
    }
    60% {
        clip: rect(98px, 9999px, 77px, 0);
    }
    65% {
        clip: rect(9px, 9999px, 95px, 0);
    }
    70% {
        clip: rect(94px, 9999px, 54px, 0);
    }
    75% {
        clip: rect(84px, 9999px, 97px, 0);
    }
    80% {
        clip: rect(9px, 9999px, 97px, 0);
    }
    85% {
        clip: rect(100px, 9999px, 67px, 0);
    }
    90% {
        clip: rect(12px, 9999px, 97px, 0);
    }
    95% {
        clip: rect(36px, 9999px, 3px, 0);
    }
    100% {
        clip: rect(37px, 9999px, 34px, 0);
    }
}
.glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: RGBA( 0 , 0 , 0 , 0.0);
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
}